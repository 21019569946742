<template>
	<div>
		<v-card>
			<v-card-title>
				{{ "Generación de compras - ventas - SIRE" }}
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<s-date
							label="Fecha Inicio"
						></s-date>
					</v-col>
					<v-col>
						<s-date
							label="Fecha Fin"
						></s-date>
					</v-col>
					<v-col>
						<v-btn
							color="primary"
							block
						>
							Generar
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import _sireService from "@/services/Sale/AccSireService.js";

	export default {
		data() {
			return {
				date: new Date().toISOString().substr(0, 7),
				menu: false,
				modal: false,
			}
		},

		v
	}
</script>
